import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationTriangle, FaHourglassHalf, FaMoneyBillWave, FaUpload } from 'react-icons/fa';
import { auth, db } from '../../firebase/config';
import './CaseStatus.css';

const CaseStatus = () => {
  const [cases, setCases] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [freeUploadsRemaining, setFreeUploadsRemaining] = useState(2);

  // Determine if a status step is active based on the current status
  const isStatusActive = (currentStatus, requiredStatus) => {
    // For free trials (first 2 uploads), all statuses except 'submitted' are active
    if (freeUploadsRemaining > 0 && requiredStatus !== 'submitted') {
      return true;
    }
    
    const statusOrder = ['uploaded', 'payment_done', 'processing', 'objection', 'submitted'];
    const currentIndex = statusOrder.indexOf(currentStatus);
    const requiredIndex = statusOrder.indexOf(requiredStatus);
    
    return currentIndex >= requiredIndex;
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'uploaded':
        return <FaUpload className="status-icon uploaded" />;
      case 'payment_done':
        return <FaMoneyBillWave className="status-icon payment" />;
      case 'processing':
        return <FaHourglassHalf className="status-icon processing" />;
      case 'objection':
        return <FaExclamationTriangle className="status-icon objection" />;
      case 'submitted':
        return <FaCheckCircle className="status-icon submitted" />;
      default:
        return <FaHourglassHalf className="status-icon pending" />;
    }
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    try {
      const d = date.toDate ? date.toDate() : new Date(date);
      return d.toLocaleDateString('en-IN', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });
    } catch (error) {
      return 'Invalid date';
    }
  };

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setError('User not authenticated');
      setLoading(false);
      return;
    }

    // Set up real-time listener for user data
    const userUnsubscribe = onSnapshot(
      doc(db, 'users', userId),
      (userDoc) => {
        if (userDoc.exists()) {
          const userUploadCount = userDoc.data().uploadCount || 0;
          setUploadCount(userUploadCount);
          setFreeUploadsRemaining(Math.max(0, 2 - userUploadCount));
        }
      },
      (error) => {
        console.error('Error listening to user data:', error);
      }
    );

    // Set up real-time listener for cases
    const casesQuery = query(
      collection(db, 'cases'),
      where('userId', '==', userId)
    );

    const casesUnsubscribe = onSnapshot(
      casesQuery,
      (querySnapshot) => {
        const casesData = [];
        querySnapshot.forEach((doc) => {
          casesData.push({ id: doc.id, ...doc.data() });
        });
        
        // Sort by date (newest first)
        casesData.sort((a, b) => {
          if (!a.uploadDate || !b.uploadDate) return 0;
          return b.uploadDate.toDate() - a.uploadDate.toDate();
        });
        
        setCases(casesData);
        setLoading(false);
        
        // Check for cases that need automatic status updates
        casesData.forEach(caseItem => {
          // For free trials, ensure status is set to processing
          if (uploadCount <= 2 && caseItem.status === 'uploaded') {
            updateDoc(doc(db, 'cases', caseItem.id), {
              status: 'processing',
              uploadTimestamp: new Date(),
              paymentTimestamp: new Date(),
              processingTimestamp: new Date()
            }).catch(error => {
              console.error('Error updating case status:', error);
            });
          }
          // If status is payment_done, update to processing after 5 seconds
          else if (caseItem.status === 'payment_done') {
            // Check if the payment was done less than 5 seconds ago
            const paymentTimestamp = caseItem.paymentTimestamp?.toDate() || new Date();
            const currentTime = new Date();
            const timeDiff = (currentTime - paymentTimestamp) / 1000; // in seconds
            
            if (timeDiff >= 5) {
              // Update status to processing
              updateDoc(doc(db, 'cases', caseItem.id), {
                status: 'processing',
                processingTimestamp: new Date()
              }).catch(error => {
                console.error('Error updating case status:', error);
              });
            } else {
              // Schedule the update after the remaining time
              const remainingTime = 5000 - (timeDiff * 1000);
              setTimeout(() => {
                updateDoc(doc(db, 'cases', caseItem.id), {
                  status: 'processing',
                  processingTimestamp: new Date()
                }).catch(error => {
                  console.error('Error updating case status:', error);
                });
              }, remainingTime);
            }
          }
        });
      },
      (error) => {
        console.error('Error listening to cases:', error);
        setError('Failed to load your cases. Please try again later.');
        setLoading(false);
      }
    );

    // Clean up listeners when component unmounts
    return () => {
      userUnsubscribe();
      casesUnsubscribe();
    };
  }, [uploadCount]);

  if (loading) {
    return <div className="loading-container">Loading your cases...</div>;
  }

  return (
    <div className="case-status-container">
      <div className="case-status-header">
        <h2>My Cases</h2>
        <div className="upload-stats">
          <div className="upload-count">
            Total Uploads: <span>{uploadCount}</span>
          </div>
          {freeUploadsRemaining > 0 ? (
            <div className="free-uploads">
              Free Uploads Remaining: <span>{freeUploadsRemaining}</span>
            </div>
          ) : (
            <div className="paid-uploads-notice">
              <FaMoneyBillWave /> Paid uploads only
            </div>
          )}
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      {cases.length === 0 ? (
        <div className="no-cases">
          <p>You haven't uploaded any cases yet.</p>
        </div>
      ) : (
        <div className="cases-list">
          {cases.map((caseItem) => (
            <div key={caseItem.id} className="case-card">
              <div className="case-header">
                <h3>{caseItem.caseId}</h3>
                <div className="case-date">
                  Uploaded: {formatDate(caseItem.uploadDate)}
                </div>
                {freeUploadsRemaining > 0 && (
                  <div className="free-trial-badge">
                    Free Trial
                  </div>
                )}
              </div>
              
              <div className="case-details">
                <div className="case-file">
                  <strong>File:</strong> {caseItem.fileName}
                </div>
                <div className="case-phone">
                  <strong>Contact:</strong> {caseItem.phoneNumber}
                </div>
              </div>
              
              <div className="case-status-timeline">
                <div className={`status-step ${isStatusActive(caseItem.status, 'uploaded') ? 'active' : ''}`}>
                  <div className="status-icon-container">
                    <FaUpload className="timeline-icon" />
                  </div>
                  <div className="status-label">Uploaded</div>
                </div>
                
                <div className="status-connector"></div>
                
                <div className={`status-step ${isStatusActive(caseItem.status, 'payment_done') ? 'active' : ''}`}>
                  <div className="status-icon-container">
                    <FaMoneyBillWave className="timeline-icon" />
                  </div>
                  <div className="status-label">Payment</div>
                </div>
                
                <div className="status-connector"></div>
                
                <div className={`status-step ${isStatusActive(caseItem.status, 'processing') ? 'active' : ''}`}>
                  <div className="status-icon-container">
                    <FaHourglassHalf className="timeline-icon" />
                  </div>
                  <div className="status-label">Processing</div>
                </div>
                
                {caseItem.status === 'objection' && (
                  <>
                    <div className="status-connector"></div>
                    <div className="status-step active">
                      <div className="status-icon-container">
                        <FaExclamationTriangle className="timeline-icon" />
                      </div>
                      <div className="status-label">Objection</div>
                    </div>
                  </>
                )}
                
                <div className="status-connector"></div>
                
                <div className={`status-step ${isStatusActive(caseItem.status, 'submitted') ? 'active' : ''}`}>
                  <div className="status-icon-container">
                    <FaCheckCircle className="timeline-icon" />
                  </div>
                  <div className="status-label">Submitted</div>
                </div>
              </div>
              
              <div className="case-status-badge">
                {getStatusIcon(caseItem.status)}
                <span>{caseItem.status.charAt(0).toUpperCase() + caseItem.status.slice(1).replace('_', ' ')}</span>
              </div>
              
              {caseItem.objectionReason && (
                <div className="objection-details">
                  <h4>Objection Details:</h4>
                  <p>{caseItem.objectionReason}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      
      {freeUploadsRemaining === 0 && (
        <div className="payment-notice">
          <h3>Free Uploads Used</h3>
          <p>You have used all your free uploads. Future uploads will require payment.</p>
          <p>Our affordable pricing ensures your cases are processed efficiently and professionally.</p>
        </div>
      )}
    </div>
  );
};

export default CaseStatus;